import React, { useState } from 'react'

import { Form, Button, Input, Space, Flex } from "antd";
import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { message } from 'antd';
import { base_URL } from '../components/api/api';
import axios from 'axios';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const params = useParams();
  const navigate = useNavigate();
  const formSubmitHandler = async (event) => {
    if (newPassword != newPasswordConfirm) {
      message.error('Passwords donot match');
      navigate(`/resetpassword/${params.id}`);
    }
    else {
      const changePasswordToken = async () => {
        const payload = {
          activationkey: params.id,
          password: newPassword,
        }
        console.log(payload)
        try {
          const response = await axios.post(`${base_URL}/user/change-password`, payload);
          // console.log(response)
          console.log("token validated:", response);
          message.success(" Password is changed!!");
          navigate(`/`)
        }
        catch (error) {
          console.error("Error submitting data:", error);
          message.error("Password couldnot be changed!")
        }
      };
      changePasswordToken();
    }
  };



  return (
    <section className="flex items-center justify-center min-h-screen px-4 py-6">
   <Form className="flex flex-col p-4 sm:p-6 items-center justify-center w-full max-w-md">
        <div className="flex justify-center mb-4 sm:mb-6">
          <LogoIcon className="w-16 h-16 sm:w-24 sm:h-24" />
        </div>
        <h2 className="text-[#3C4A60D9] roboto-medium text-2xl sm:text-[30px] text-center">
          Reset Password
        </h2>

  <Flex vertical gap={12} className="mt-10 w-full">
          <Space direction="vertical" className="w-full">
            <Input.Password
              placeholder='New Password'
               className="rounded-none text-black w-full"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </Space>
          <Space direction="vertical" className="gap-4">
            <Input.Password
              placeholder='Confirm New Password'
              className="rounded-none text-black w-full"
              onChange={(e) => {
                setNewPasswordConfirm(e.target.value);
              }}
            />
          </Space>
          <Space direction="vertical" className="mt-2">
            <Button
              block
              type="primary"
              className="rounded-none roboto-regular"
              onClick={formSubmitHandler}
            >
              Confirm Password
            </Button>
            {/* <Button
              block
              className="bg-[#FFFFFF] rounded-none roboto-regular text-[#3C4A60D9]"
            >
              Sign Up
            </Button> */}
          </Space>

        </Flex>

        <p className="mt-8 text-sm roboto-regular">
          {/* <Button type="link" className="text-primary roboto-regular">
            Create Account
          </Button>
          <span>Or</span> */}
          <Button type="link" className="text-primary roboto-regular" href='/'>
            Back to Login
          </Button>
        </p>
      </Form>
    </section>
  )
}

export default ResetPassword;