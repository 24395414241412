import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import { Spin } from "antd";

import LoginPage from "../pages/Login.js";

import RootLayout from "./root.js";
import { checkAuthLoader } from "../components/auth";
import ValidateToken from "../pages/ValidateToken.js";
import ResetPassword from "../pages/ResetPassword.js";
import { OrgSetup } from "../pages/Other/OrgSetup/OrgSetup.js";
import { PPMSetup } from "../pages/Other/ppmSetup/PPMSetup.js";

const Activity = lazy(() =>
  import("../pages/configuration/Activity/Activity.js")
);

const ActivityTag = lazy(() =>
  import("../pages/Other/Activity/ActivityTag.js")
);

const ActivityTask = lazy(() =>
  import("../pages/configuration/Task/ActivityTask.js")
);

const Employee = lazy(() =>
  import("../pages/configuration/Employee/Employee.js")
);

const ProfilePage = lazy(() => import("../pages/Home/Tabs/Profile.js"));

const RolePage = lazy(() => import("../pages/configuration/Role/Role.js"));
const SupportUser = lazy(() =>
  import("../pages/configuration/Users/SupportUser/Users.js")
);
const SubsidiaryPage = lazy(() =>
  import("../pages/configuration/Users/SubsidiaryUsers/Users.js")
);
const ProductAdminPage = lazy(() =>
  import("../pages/configuration/Users/ProductAdmin/Users.js")
);
const SubsidiaryAdminPage = lazy(() =>
  import("../pages/configuration/Users/SubsidiaryAdmin/Users.js")
);

const ChannelPartnersPage = lazy(() =>
  import("../pages/configuration/Users/ChannelPartners/Users.js")
);

const BusinessPartnersPage = lazy(() =>
  import("../pages/configuration/Users/BusinessPartner/Users.js")
);

const HomePage = lazy(() => import("../pages/Home/Home.js"));

const Country = lazy(() => import("../pages/Other/Country/Country.js"));
const Qualification = lazy(() =>
  import("../pages/Other/Qualifications/Qualification.js")
);
const TaxHeads = lazy(() => import("../pages/Other/TaxHeads/TaxHeads.js"));
const State = lazy(() => import("../pages/Other/States/State.js"));
const City = lazy(() => import("../pages/Other/City/City.js"));
const Currency = lazy(() => import("../pages/Other/Currency/Currency.js"));
const TimeZone = lazy(() => import("../pages/Other/TimeZone/TimeZone.js"));

const Salutation = lazy(() =>
  import("../pages/Other/Salutation/Salutation.js")
);
const BloodGroup = lazy(() =>
  import("../pages/Other/BloodGroup/BloodGroup.js")
);
const Band = lazy(() => import("../pages/Other/Band/Band.js"));
const ActivityTaskTag = lazy(() =>
  import("../pages/Other/Task/ActivityTaskTag/ActivityTaskTag.js")
);
const BusinessUnit = lazy(() =>
  import("../pages/Other/BusinessUnit/BusinessUnit.js")
);

const WorkLocation = lazy(() =>
  import("../pages/Other/WorkLocation/WorkLocation.js")
);
const Experience = lazy(() =>
  import("../pages/Other/Experience/Experience.js")
);
const Shift = lazy(() => import("../pages/Other/Shift/Shift.js"));

const EmployeeStatus = lazy(() =>
  import("../pages/Lookups/EmployeeStatus/EmployeeStatus.js")
);

const EmployeeType = lazy(() =>
  import("../pages/Lookups/EmployeeType/EmployeeType.js")
);

const EmployeeSubType = lazy(() =>
  import("../pages/Lookups/EmployeeSubType/EmployeeSubType.js")
);

const Department = lazy(() =>
  import("../pages/Other/Department/Department.js")
);
const Designation = lazy(() =>
  import("../pages/Other/Designation/Designation.js")
);
const Education = lazy(() => import("../pages/Other/Education/Education.js"));
const WeekOff = lazy(() => import("../pages/Other/WeekOff/WeekOff.js"));
const Nationality = lazy(() =>
  import("../pages/Other/Nationality/Nationality.js")
);

const KeySkills = lazy(() => import("../pages/Other/KeySkills/KeySkills.js"));

const LeaveType = lazy(() => import("../pages/Other/LeaveType/LeaveType.js"));

const TenantPage = lazy(() =>
  import("../pages/configuration/Tenant/Tenant.js")
);
const BPTenantPage = lazy(() => import("../pages/BPTenant/Tenant.js"));
const Error404 = lazy(() => import("../components/error.js"));

const ForgotPassword = lazy(() => import("../pages/forgotPassword.js"));

const BaseLocation = lazy(() => import("../pages/Other/BaseLocation/BaseLocation.js"));

const MyTaskReports = lazy(() => import("../pages/Other/Reports/MyTaskReports.js"));


const contentStyle = {
  padding: 50,
  background: "rgba(0, 0, 0, 0.05)",
  borderRadius: 4,
};

const content = <div style={contentStyle} />;

export const tenantRouter = createBrowserRouter([
  { path: "/", element: <LoginPage /> },
  {
    path: "/*",
    element: <Error404 />,
  },
  {
    path: "/forgotpassword",
    element: (
      <Suspense fallback={<Spin tip="Loading">{content}</Spin>}>
        <ForgotPassword />
      </Suspense>
    ),
  },
  {
    path: "/validatetoken/:id",
    element: <ValidateToken />,
  },
  {
    path: "/resetpassword/:id",
    element: <ResetPassword />,
  },
  {
    path: "/",
    element: <RootLayout />,
    loader: checkAuthLoader,
    children: [
      {
        path: "/dashboard",
        element: <HomePage />,
        loader: checkAuthLoader,
      },
      { path: "/Activity", element: <Activity />, loader: checkAuthLoader },
      {
        path: "/activitytask",
        element: <ActivityTask />,
        loader: checkAuthLoader,
      },
      {
        path: "/activity-tag",
        element: <ActivityTag />,
        loader: checkAuthLoader,
      },
      {
        path: "/activity-task-tags",
        element: <ActivityTaskTag />,
        loader: checkAuthLoader,
      },
      {
        path: "/Employee",
        element: <Employee />,
        loader: checkAuthLoader,
      },
      {
        path: "/country",
        element: <Country />,
        loader: checkAuthLoader,
      },
      {
        path: "/state",
        element: <State />,
        loader: checkAuthLoader,
      },
      {
        path: "/base-locations",
        element: <BaseLocation />,
        loader: checkAuthLoader,
      },
      {
        path: "/city",
        element: <City />,
        loader: checkAuthLoader,
      },
      {
        path: "/orgsetup",
        element: <OrgSetup />,
        loader: checkAuthLoader,
      },
      {
        path: "/ppmsetup",
        element: <PPMSetup />,
        loader: checkAuthLoader,
      },

      {
        path: "/currency",
        element: <Currency />,
        loader: checkAuthLoader,
      },
      {
        path: "/tax-heads",
        element: <TaxHeads />,
        loader: checkAuthLoader,
      },
      {
        path: "/timezone",
        element: <TimeZone />,
        loader: checkAuthLoader,
      },

      {
        path: "/salutations",
        element: <Salutation />,
        loader: checkAuthLoader,
      },
      {
        path: "/bloodgroup",
        element: <BloodGroup />,
        loader: checkAuthLoader,
      },
      {
        path: "/band",
        element: <Band />,
        loader: checkAuthLoader,
      },
      {
        path: "/businessunit",
        element: <BusinessUnit />,
        loader: checkAuthLoader,
      },
      {
        path: "/worklocation",
        element: <WorkLocation />,
        loader: checkAuthLoader,
      },
      {
        path: "/experience",
        element: <Experience />,
        loader: checkAuthLoader,
      },
      {
        path: "/shifts",
        element: <Shift />,
        loader: checkAuthLoader,
      },
      {
        path: "/profile",
        element: <ProfilePage />,
        loader: checkAuthLoader,
      },
      {
        path: "/qualifications",
        element: <Qualification />,
        loader: checkAuthLoader,
      },
      {
        path: "/educations",
        element: <Education />,
        loader: checkAuthLoader,
      },

      {
        path: "/departments",
        element: <Department />,
        loader: checkAuthLoader,
      },
      {
        path: "/designations",
        element: <Designation />,
        loader: checkAuthLoader,
      },
      {
        path: "/skills",
        element: <KeySkills />,
        loader: checkAuthLoader,
      },
      {
        path: "/leave-types",
        element: <LeaveType />,
        loader: checkAuthLoader,
      },
      {
        path: "/weekoff",
        element: <WeekOff />,
        loader: checkAuthLoader,
      },
      {
        path: "/nationality",
        element: <Nationality />,
        loader: checkAuthLoader,
      },
      {
        path: "/tenant",
        element: <TenantPage />,
        loader: checkAuthLoader,
      },
      {
        path: "/bptenant",
        element: <BPTenantPage />,
        loader: checkAuthLoader,
      },
      {
        path: "/role",
        element: <RolePage />,
        loader: checkAuthLoader,
      },
      {
        path: "/support-users",
        element: <SupportUser />,
        loader: checkAuthLoader,
      },
      {
        path: "/subsidiary-users",
        element: <SubsidiaryPage />,
        loader: checkAuthLoader,
      },
      {
        path: "/product-admin",
        element: <ProductAdminPage />,
        loader: checkAuthLoader,
      },
      {
        path: "/subsidiary-admin",
        element: <SubsidiaryAdminPage />,
        loader: checkAuthLoader,
      },
      {
        path: "/channel-partners",
        element: <ChannelPartnersPage />,
        loader: checkAuthLoader,
      },
      {
        path: "/business-partner",
        element: <BusinessPartnersPage />,
        loader: checkAuthLoader,
      },
      {
        path: "/employee-status",
        element: <EmployeeStatus />,
        loader: checkAuthLoader,
      },
      {
        path: "/employee-type",
        element: <EmployeeType />,
        loader: checkAuthLoader,
      },
      {
        path: "/employee-sub-type",
        element: <EmployeeSubType />,
        loader: checkAuthLoader,
      },
      {
        path: "/mactr",
        element: <MyTaskReports  isActivty={true} />,
        loader: checkAuthLoader,
      },
      {
        path: "/mtskr",
        element: <MyTaskReports isActivty={false} />,
        loader: checkAuthLoader,
      },
      {
        path: "/*",
        element: <Error404 />,
      },
    ],
  },
]);
