import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";
import { Breadcrumb, Layout, Menu, theme, Button } from "antd";
import { postRequest } from "./api/api";
import * as AntIcons from "@ant-design/icons";
import { openDrawer } from "../store/slices/theme-slice";
import TopHeader from "./Header";
import { setPermissions, isNotifcation } from "../store/slices/tenant-slice";
// import {  } from '../store/slices/tenant-slice'

import "../assets/css/Slider.css";
const { Sider } = Layout;

const SideTopbar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const tenantId = useSelector((state) => state.tenant.tenantId);
  const tenantSubsidiaryId = useSelector(
    (state) => state.tenant.tenantSubsidiaryId
  );
  const userId = useSelector((state) => state.auth.userId);

  const getIcon = (iconName) => {
    return AntIcons[iconName] ? React.createElement(AntIcons[iconName]) : null;
  };




  const loadMenus = async () => {
    try {
      if (userId == undefined || userId == null || userId=="" || tenantId == null || tenantId == "" || tenantSubsidiaryId == null || tenantSubsidiaryId == "") {
        return;
      }
      let api = `/role-types/getModulesByUserId`;
      const payload = {
        tenantId,
        tenantSubsidiaryId,
        userId,
      };
      const apiresponse = await postRequest(api, payload);
      if (apiresponse) {
        
        const response=apiresponse.filter(x=>x.ModuleCategory!="Help")
        console.log("*********************",response)
        const groupedData = response.reduce((acc, module) => {
          // if (module.ModuleCategory != "Help") {

          const category = acc[module.ModuleCategory] || {
            key: module.ModuleCategoryID,
            label: module.ModuleCategory,
            icon: getIcon(module.CatIcon),
            children: [],
          };
          category.children.push({
            key: module.NavigateUrl,
            label: module.Module,
            icon: getIcon(module.Icon),
          });
          acc[module.ModuleCategory] = category;
          return acc;
          // }
        }, {});
        
        setMenuItems(Object.values(groupedData));

        let permissions = [];
        response.forEach((module) => {
          const moduleRights = JSON.parse(module.ModuleRights);
          module.permissions = moduleRights.ModuleRoleFunctions;
          permissions.push(module);
        });
        localStorage.setItem("permissions", JSON.stringify(permissions));
        dispatch(setPermissions(permissions));
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  useEffect(() => {
    loadMenus();
  }, [tenantId, tenantSubsidiaryId, userId]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const siderStyle = {
    overflow: "auto",
    height: "100vh",
    position: "fixed",
    top: 40,
    bottom: 0,
    background: colorBgContainer,
  };

  const handleMenuClick = (item) => {
    dispatch(isNotifcation(false))
    navigate(item.key);
  };


  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find(key => !openKeys.includes(key));
    const filteredKeys = latestOpenKey ? [latestOpenKey] : [];
    setOpenKeys(filteredKeys);
  };

  const renderMenuItem = (item) => {
    if (item.children) {
      return (
        <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
          {item.children.map(renderMenuItem)}
        </Menu.SubMenu>
      );
    }

    return (
      <Menu.Item
        key={item.key}
        icon={item.icon}
        // className={`custom-menu-item ${item.key === location.pathname ? "active" : ""
        //   }`}
        onClick={() => {
          handleMenuClick(item)

          dispatch(isNotifcation(false))

        }}
      >
        {item.label}
      </Menu.Item>
    );
  };

  return (
    <Layout>
      <TopHeader
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        colorBgContainer={colorBgContainer}
      />
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={siderStyle}
          width={250}
          collapsedWidth={80}
          className="hidden sm:block"
        >
          <Menu
            mode="inline"
            className="custom-menu text-[14px] roboto-regular bg-[#F8FAFC]"
            selectedKeys={[location.pathname]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            inlineCollapsed={collapsed}
          >
            {menuItems.map(renderMenuItem)}
          </Menu>
          {/* <div className="roboto-medium mt-[36rem] text-center border-2 p-2 m-2">
            <h2>Logo</h2>
            <h2>CP Name</h2>
            <h2>CP No</h2>
          </div> */}
        </Sider>

        <Layout
          className={`mt-0 ${collapsed
            ? "responsive-margin-collapsed"
            : "responsive-margin-expanded"
            } responsive-margin`}
          style={{ transition: "margin-left 0.2s" }}  
        >
          {props.children}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default SideTopbar;
