import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cookies from "react-cookies";

import {
  Dropdown,
  Switch,
  Layout,
  Space,
  Drawer,
  Menu,
  Select,
  Avatar,
  message,
  Badge
} from "antd";
import { UserOutlined, BellOutlined, LogoutOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import * as AntIcons from "@ant-design/icons";
import { AppUserType, userTypeMap } from "../constants/Constant";

import { ReactComponent as DrawerIcon } from "../assets/drawer.svg";
import { toggleTheme } from "../store/slices/theme-slice";
import {
  allSubsidiaries,
  setLogoUrl,
  setRoleName,
  setRoleTypeId,
  setSubsidiaryName,
  setTenantId,
  setTenantName,
  setTenantSubsidiaryId,
  setUserType,
} from "../store/slices/tenant-slice";
import { isLogout, setName } from "../store/slices/auth-slice";
import "../assets/css/Slider.css";
import { getRequest } from "./api/api";
import logo_Url from "../assets/logo.svg";
import { postRequest } from "./api/api";
import { isNotifcation } from '../store/slices/tenant-slice'
import { fetchDataFromApi } from "./function";

const { Header } = Layout;
const { Option } = Select;

const TopHeader = ({ collapsed, setCollapsed }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const [options, setOptions] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [HelpMenuItems, setHelpMenuItems] = useState([]);
  const [mobileMenu, setMobileMenu] = useState([])
  const [count, setCount] = useState(0)
  const [iscount, setIsCount] = useState(0)
  const [refresh, setRefresh] = useState(0);
  const [helpDrop, setHelpDrop] = useState([])






  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const logoUrl = useSelector((state) => state.tenant.logoUrl);
  const tenantId = useSelector((state) => state.tenant.tenantId);
  const isNotification = useSelector((state) => state.tenant.isNotification);
  const name = useSelector((state) => state.auth.name);
  const roleName = useSelector((state) => state.tenant.roleName) || "role";

  const subsidiaryName = useSelector((state) => state.tenant.subsidiaryName);
  const userId = useSelector((state) => state.auth.userId);
  const tenantSubsidiaryId = useSelector(
    (state) => state.tenant.tenantSubsidiaryId
  );

  const navigate = useNavigate();

  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleThemeChange = (checked) => {
    dispatch(toggleTheme());
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find(key => !openKeys.includes(key));
    const filteredKeys = latestOpenKey ? [latestOpenKey] : [];
    setOpenKeys(filteredKeys);
  };

  const fetchOptions = async () => {
    try {
      const response = await getRequest("/tenantSubsidiaries");

      // console.log(numUnreadNtification, 'numUnreadNtification');

      setOptions(response);
      if (tenantId && tenantSubsidiaryId) {
        const numUnreadNtification = await getRequest(`/activity-tasks/unseentaskcount/${tenantId}/${tenantSubsidiaryId}`)
        console.log(numUnreadNtification,'numUnreadNtification');

        setCount(numUnreadNtification)

      }

    } catch (error) {
      console.error("Failed to fetch tenant subsidiaries:", error);
    }
  };




  useEffect(() => {
    fetchOptions();

  }, []);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // const response = await getRequest("/tenantSubsidiaries");
        if (tenantId && tenantSubsidiaryId) {

          //const numUnreadNtification = await getRequest(`/activity-tasks/unseentaskcount?filter[where][tenantId]=${tenantId}&filter[where][tenantSubsidiaryId]=${tenantSubsidiaryId}&filter[where][assignedTo]=${userId}`)
          const numUnreadNtification = await getRequest(`/activity-tasks/unseentaskcount/${tenantId}/${tenantSubsidiaryId}`)
          console.log(numUnreadNtification,'numUnreadNtification');
          
          setCount(numUnreadNtification)

        }
        // console.log(numUnreadNtification, 'numUnreadNtification');

        // setOptions(response);


      } catch (error) {
        console.error("Failed to fetch tenant subsidiaries:", error);
      }
    };

    fetchOptions();
  }, [isNotification]);

  useEffect(() => {
    loadMenus();
    fetchOptions();
  }, [tenantId, tenantSubsidiaryId, userId]);

  const getIcon = (iconName) => {
    return AntIcons[iconName] ? React.createElement(AntIcons[iconName]) : null;
  };
  const loadMenus = async () => {
    try {
      if ((tenantId != null && tenantId != "") && (tenantSubsidiaryId != null && tenantSubsidiaryId != "") && (userId != null && userId != "")) {


        let api = `/role-types/getModulesByUserId`;
        const payload = {
          tenantId,
          tenantSubsidiaryId,
          userId,
        };
        const category1 = []
        let mobileMenu = []
        let Menu = []
        let mcategory = {}
        const response = await postRequest(api, payload);
        // console.log(response, 'ressssss');

        const menu = response?.map(el => {
          if (el.Module == "My Details" || el.Module == "Task Overview" || el.ModuleCategory == "Task Sync") {
            // console.log(el, 'elllllll');
            mobileMenu.push({
              key: el.NavigateUrl,
              label: el.Module,
              icon: getIcon(el.Icon),
            })


          }
        });

        // console.log(mobileMenu, 'mobileMenu');
        setMobileMenu(mobileMenu)

        const [HTMLHelpData] =
          await Promise.all([
            fetchDataFromApi(`/lookups/?filter[where][type]=HELPHTML`),
          ]);
        // console.log(HTMLHelpData, 'HTMLHelpDataHTMLHelpData');
        setHelpDrop(HTMLHelpData)

        if (response) {
          const groupedData = response.reduce((acc, module) => {
            if (module.ModuleCategory == "Help") {
              category1.push(module)
              setHelpMenuItems(category1)
            }

            const category = acc[module.ModuleCategory] || {
              key: module.ModuleCategoryID,
              label: module.ModuleCategory,
              icon: getIcon(module.CatIcon),
              children: [],
            };
            category.children.push({
              key: module.NavigateUrl,
              label: module.Module,
              icon: getIcon(module.Icon),
            });
            acc[module.ModuleCategory] = category;
            return acc;




          }, {});

          setMenuItems(Object.values(groupedData));
        }
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleMenuClick = (item) => {
    navigate(item.key);
    setDrawerVisible(false);
  };

  const renderMenuItem = (item) => {
    // if (item.children) {
    //   return (
    //     <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
    //       {item.children.map(renderMenuItem)}
    //     </Menu.SubMenu>
    //   );
    // }

    return (
      <Menu.Item
        key={item.key}
        icon={item.icon}
        className={`custom-menu-item ${item.key === location.pathname ? "active" : ""
          }`}
        onClick={() => handleMenuClick(item)}
      >
        {item.label}
      </Menu.Item>
    );
  };

  const logoutHandler = () => {
    dispatch(isLogout());

    localStorage.clear();

    navigate("/");
  };

  const items = options.map((option) => ({
    key: option.tenantSubsidiaryId,
    label: (
      <div
        className="flex gap-6"
        onClick={() =>
          handleSelect({
            roleName: option.roleName,
            name: option.name,
            tenantSubsidiaryId: option.tenantSubsidiaryId,
          })
        }
      >
        <img className="w-6 h-6" alt="logo" src={option.logoUrl || ""} />{" "}
        {option.tenantSubsidiaryName}
      </div>
    ),
  }));


  // console.log(helpDrop,'helpDrophelpDrop');

  const sortedData = helpDrop.sort((a, b) => {
    const order = ['Contact Us', 'Report Ticket', 'Activity and Task Help']; // define the desired order
    return order.indexOf(a.name) - order.indexOf(b.name); // compare based on custom order
  });

  const helpItem = sortedData.map((option, index) => ({

    key: index,
    label: (
      <a
        className="flex gap-6"
        href={option.value} target="_blank"
      // navigate(option.NavigateUrl)
      // handleHelpClick(option)

      >
        {/* <img className="w-6 h-6" alt="logo" src={"" || ""} />{" "} */}
        {option.name.includes("Activity") ? "Refer" : option.name}
      </a>
    ),
  }));

  // console.log(helpItem, 'help menu items ======================>');

  const [selectedSubsidiary, setSelectedSubsidiary] = useState({
    roleName: roleName,
    name: name,
    tenantSubsidiaryId: tenantSubsidiaryId,
  });

  const handleSelect = async (subsidiary) => {
    setSelectedSubsidiary(subsidiary);

    const extractedData = options.find(
      (data) => data.tenantSubsidiaryId === subsidiary.tenantSubsidiaryId
    );

    localStorage.setItem("name", extractedData.name);
    localStorage.setItem("logoUrl", extractedData.logoUrl);
    localStorage.setItem("roleName", extractedData.roleName);
    localStorage.setItem("roleTypeId", extractedData.roleTypeId);
    localStorage.setItem("tenantId", extractedData.tenantId);
    localStorage.setItem("tenantName", extractedData.tenantName);
    localStorage.setItem(
      "tenantSubsidiaryId",
      extractedData.tenantSubsidiaryId
    );
    localStorage.setItem(
      "tenantSubsidiaryName",
      extractedData.tenantSubsidiaryName
    );

    localStorage.setItem("userType", userTypeMap[extractedData.userType]);

    dispatch(setName(extractedData.name));
    dispatch(setRoleName(extractedData.roleName));
    dispatch(setRoleTypeId(extractedData.roleTypeId));
    dispatch(setLogoUrl(extractedData.logoUrl));
    dispatch(setTenantId(extractedData.tenantId));
    dispatch(setTenantSubsidiaryId(extractedData.tenantSubsidiaryId));
    dispatch(setSubsidiaryName(extractedData.tenantSubsidiaryName));
    dispatch(setTenantName(extractedData.tenantName));

    dispatch(setUserType(userTypeMap[extractedData.userType]));

    const responseLog = await postRequest("/users/updateuserlogs", {
      tenantId,
      tenantSubsidiaryId,
    });

    if (responseLog[0].result) {
      message.success("Subsidiary changed!");
    }

    navigate("/dashboard");
  };

  useEffect(() => {
    if (name && roleName && tenantSubsidiaryId) {
      setSelectedSubsidiary({
        roleName: roleName,
        name: name,
        tenantSubsidiaryId: tenantSubsidiaryId,
      });
    }
  }, [roleName, name, tenantSubsidiaryId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 640) {
        setDrawerVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log(menuItems, 'menuItems');







  return (
    <Header className="sticky top-0 h-14 z-10 flex items-center shadow-md shadow-[#EAEFF5] justify-between p-0 bg-white">
      <Space className="ml-5">
        <DrawerIcon
          className="sm:hidden mr-2 text-[#3C4A60D9] cursor-pointer"
          onClick={() => setDrawerVisible(true)}
        />
        <DrawerIcon
          className="hidden sm:block mr-2 text-[#3C4A60D9] cursor-pointer"
          onClick={() => setCollapsed(!collapsed)}
        />
        <Space className="flex items-center">
          <img src={logo_Url} />
          <h5 className=" hidden md:block text-[#7E93AB] roboto-bold text-[1.25em]">
            Re-Aligno
          </h5>
        </Space>
      </Space>
      <Space className="flex items-center">
        <img
          src={logoUrl || logo_Url}
          alt="Logo"
          onError={(e) => {
            e.target.src = logo_Url;
          }}
          className="w-12 h-[1.6rem]"
        />
        <h5 className="text-[#7E93AB] hidden md:block roboto-medium text-[16px]">
          {subsidiaryName}
        </h5>
      </Space>

      <div className="flex mr-4 items-center space-x-4">


        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <Space>
            <p className="hidden md:block">
              {`${selectedSubsidiary.name} (${selectedSubsidiary.roleName})`}
            </p>
            <UserOutlined className="w-4 h-4 text-[16px] cursor-pointer" />
          </Space>
        </Dropdown>
        <Badge count={count} style={{
          width: '20px', // Set a smaller width
          height: '20px', // Set a smaller height
          fontSize: '12px', // Reduce font size
        }} onClick={() => {
          // setCount(0)
          setIsCount(false)
          // dispatch(isNotifcation(true))
          navigate(`/activitytask`, { state: { selectedStatus: "Assigned" } });

        }}>

          <BellOutlined className="w-4 h-4 text-[16px] cursor-pointer" />
        </Badge>
        <Dropdown
          menu={{
            items: helpItem,
            // onClick: handleHelpClick,
          }}
          // menu={{menu}}
          trigger={["click"]}
        >

          <Space>

            <QuestionCircleOutlined className="mt-6 w-4 h-4 text-[16px] cursor-pointer" />
          </Space>
        </Dropdown>
        <LogoutOutlined
          className="w-4 h-4 text-[16px] cursor-pointer"
          onClick={logoutHandler}
        />
      </div>

      <Drawer
        title="Menu"
        placement="left"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        className="sm:hidden"
      >
        <Menu
          theme="light"
          mode="inline"
          className="custom-menu"
          onClick={handleMenuClick}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          inlineCollapsed={collapsed}
        >
          {mobileMenu.map(renderMenuItem)}
        </Menu>
      </Drawer>
    </Header>
  );
};

export default TopHeader;
