import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    idToken: "",
    userId: "",
    userName: "",
    name: "",
    code: "",
    dateFormat: "DD-MM-YYYY",
    dateAndTimeFormat: "DD-MM-YYYY",
    email:"",
    employeeId:""
  },
  reducers: {
    isLogin(state) {
      state.isAuthenticated = true;
    },
    isLogout(state) {
      state.isAuthenticated = false;
    },
    setIdToken(state, action) {
      state.idToken = action.payload;
    },
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setUserName(state, action) {
      state.userName = action.payload;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setCode(state, action) {
      state.code = action.payload;
    },
    setDateFormat(state, action) {
      state.dateFormat = action.payload;
    },
    setDateAndTimeFormat(state, action) {
      state.dateAndTimeFormat = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setEmployeeId(state, action) {
      state.employeeId = action.payload;
    },
  },
});

export const {
  isLogin,
  isLogout,
  setIdToken,
  setUserId,
  setUserName,
  setName,
  setCode,
  setDateFormat,
  setDateAndTimeFormat,
  setEmail,
  setEmployeeId
} = authSlice.actions;

export default authSlice.reducer;
