import { createSlice } from "@reduxjs/toolkit";

const tenantSlice = createSlice({
  name: "tenant",
  initialState: {
    logoUrl: "",
    tenantName: "",
    subsidiaryName: "",
    tenantId: "",
    tenantSubsidiaryId: "",
    tenants: [],
    roleName: "",
    roleTypeId: "",
    subsidiaries: [],
    permissions: [],
    userType: "",
    isNotification:false,
    isEmployeeEdited:false
  },
  reducers: {
    setLogoUrl(state, action) {
      state.logoUrl = action.payload;
    },
    setTenantName(state, action) {
      state.tenantName = action.payload;
    },
    setSubsidiaryName(state, action) {
      state.subsidiaryName = action.payload;
    },
    setRoleName(state, action) {
      state.roleName = action.payload;
    },
    setRoleTypeId(state, action) {
      state.roleTypeId = action.payload;
    },
    setTenantId(state, action) {
      state.tenantId = action.payload;
    },
    setTenantSubsidiaryId(state, action) {
      state.tenantSubsidiaryId = action.payload;
    },
    setUserType(state, action) {
      state.userType = action.payload;
    },
    setPermissions(state, action) {
      state.permissions = action.payload;
    },
    allTenants(state, action) {
      state.tenants = action.payload;
    },
    addTenant(state, action) {
      state.tenants.push(action.payload);
    },
    updateTenant(state, action) {
      const { ID, updatedTenant } = action.payload;
      const index = state.tenants.findIndex((tenant) => tenant.ID === ID);
      if (index !== -1) {
        state.tenants[index] = { ...state.tenants[index], ...updatedTenant };
      }
    },
    deleteTenant(state, action) {
      const ID = action.payload;
      state.tenants = state.tenants.filter((tenant) => tenant.ID !== ID);
    },

    allSubsidiaries(state, action) {
      state.subsidiaries = action.payload;
    },

    isNotifcation(state, action) {
      // console.log(action,'actionnn');
      
      state.isNotification = action.payload;
    },
    editEmployee(state, action) {
      // console.log(action,'actionnn');
      
      state.isEmployeeEdited = action.payload;
    },
    
    // addSubsidiary(state, action) {
    //   state.subsidiaries.push(action.payload);
    // },
    // updatesubsidiary(state, action) {
    //   const { ID, updatedSubsidiary } = action.payload;
    //   const index = state.subsidiaries.findIndex(
    //     (subsidiary) => subsidiary.ID === ID
    //   );
    //   if (index !== -1) {
    //     state.subsidiaries[index] = {
    //       ...state.subsidiaries[index],
    //       ...updatedSubsidiary,
    //     };
    //   }
    // },
    // deletesubsidiary(state, action) {
    //   const ID = action.payload;
    //   state.subsidiaries = state.subsidiaries.filter(
    //     (subsidiary) => subsidiary.ID !== ID
    //   );
    // },
  },
});

export const {
  setLogoUrl,
  setSubsidiaryName,
  setTenantId,
  setTenantSubsidiaryId,
  setRoleName,
  setRoleTypeId,
  allTenants,
  addTenant,
  updateTenant,
  deleteTenant,
  allSubsidiaries,
  setTenantName,
  setUserType,
  setPermissions,
  isNotifcation,
  editEmployee
} = tenantSlice.actions;

export default tenantSlice.reducer;
