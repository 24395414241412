import { getRequest } from "./api/api";
import dayjs from "dayjs";

/**
 * Fetch data from the given API URL.
 * @param {string} url - The API endpoint to fetch data from.

 */

/**
 * Calculates and formats the duration between a given date and today.
 * @param {string | Date} startDate - The starting date.
 * @returns {string} - The formatted duration as "X year(s) Y month(s)".
 */

export const fetchDataFromApi = async (url) => {
  try {
    const response = await getRequest(url);
    return response;
  } catch (error) {
    console.error("Error fetching subsidiary types:", error);
  }
};

export const fetchDataWithGet = async (url) => {
  try {
    return await getRequest(url);
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    return [];
  }
};

export const calculateDuration = (startDate) => {
  const today = dayjs();
  const joiningDate = dayjs(startDate);

  if (!joiningDate.isValid()) return "";

  const years = today.diff(joiningDate, "year");
  const months = today.diff(joiningDate.add(years, "year"), "month");

  return `${years} year ${months} month`;
};

export const formatDobAndAge = (dob) => {
  if (!dob) return "";

  const birthDate = dayjs(dob);
  if (!birthDate.isValid()) return "";

  const today = dayjs();
  const years = today.diff(birthDate, "year");
  const months = today.diff(birthDate.add(years, "year"), "month");

  return `${birthDate.format("MMM D, YYYY")} - ${years} year${
    years > 1 ? "s" : ""
  }, ${months} month${months > 1 ? "s" : ""}`;
};

export const disablePastDates = (current) => {
  return current && current < dayjs().startOf("day");
};
