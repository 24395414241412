export const AppUserType = Object.freeze({
  SUPERADMIN: "a5f28d4e-9b6a-4a83-bb85-b58bc84b0f85",
  SUBSIDIARY: "3d29f638-9a7f-4e82-8d0a-2b0a243a8a7d",
  PARTNER: "9b77f8f7-4e47-4f56-a9f7-1bc26a8a2a9e",
  CHANNELPARTNER: "3b77f8f7-4e47-4f56-a9f7-1bc26a8a2a9e",
  SUBSIDIARYUSER: "6b77f8f7-4e47-4f56-a9f7-1bc26a8a2a9e",
  // SUPERADMIN: "160fe854-c2fb-4afe-8866-36b12b37d811",
  // SUBSIDIARY: "da904f61-832c-4408-b11e-7ce2356e39a3",
  // PARTNER: "c04bf1fd-500a-41a4-aadc-eaf1710ef681",
  // CHANNELPARTNER: "74ac6de3-a6b2-4692-855a-4fb5ca054313",
  // SUBSIDIARYUSER: "da7cc161-56f6-4da3-a06a-266cccb1fadc",
});


export const userTypeMap = {
  "Product Admin": AppUserType.SUPERADMIN,
  "Subsidiary Admin": AppUserType.SUBSIDIARY,
  "Business Partner": AppUserType.PARTNER,
  "Subsidiary Users": AppUserType.SUBSIDIARYUSER,
  "Channel Partners": AppUserType.CHANNELPARTNER,

};
